import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

function Tooltip({ title, mouse, children, position,forceHide }) {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [tooltipPos, setTooltipPos] = useState(false);
  const [parentPos, setParentPos] = useState(false);
  const tooltipRef = useRef(null);
  const parentRef = useRef(null);
  const { t } = useTranslation("global");
  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  useEffect(() => {
    if (tooltipRef.current != null) {
      setTooltipPos(tooltipRef.current.getBoundingClientRect());
    }
    if (parentRef.current != null) {
      setParentPos(parentRef.current.getBoundingClientRect());
    }
  }, []);
  if (forceHide){return <>{children}</>}
  // console.log(`${title} - ${position}`)
  switch (position) {
    case "mouse":
      return (
        <>
          {" "}
          <div className="has-tooltip" ref={parentRef}>
            {children}
            {title && (
              <div
                className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow justify-start tooltip text-fwhite max-w-[400px] text-xs delay-750 transition-opacity "
                style={{
                  top: `${mousePos.y + 20}px`,
                  left: `${mousePos.x + 20}px`,
                }}
                ref={tooltipRef}
              >
                {t(title, title)}
              </div>
            )}
          </div>
        </>
      );
      break;
    case "top":
    case "top-start":
      return (
        <>
          <div className="has-tooltip" ref={parentRef}>
            <>
              {title && (
                <div
                  className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow justify-start mt-1  tooltip text-fwhite max-w-[400px] text-xs transition-opacity "
                  ref={tooltipRef}
                  style={{
                    marginTop: -tooltipPos.height,
                  }}
                >
                  {t(title, title)}
                </div>
              )}
              {children}
            </>
          </div>
        </>
      );
      break;
    case "top-end":
      return (
        <>
          <div className="has-tooltip justify-end" ref={parentRef}>
            <>
              {title && (
                <div
                  className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow justify-start mt-1  tooltip text-fwhite max-w-[400px] text-xs transition-opacity "
                  ref={tooltipRef}
                  style={{
                    marginTop: -tooltipPos.height,
                    marginLeft:
                      tooltipPos.height <= 40 ? 0 : -(400 - tooltipPos.width),
                  }}
                >
                  {t(title, title)}
                </div>
              )}{" "}
              {children}
            </>
          </div>
        </>
      );
      break;
    case "bottom-end":
      return (
        <>
          {" "}
          <div className="has-tooltip" ref={parentRef}>
            <>
              {children}
              {title && (
                <div
                  className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow  mt-1  tooltip text-fwhite max-w-[400px] absolute text-xs transition-opacity "
                  ref={tooltipRef}
                  style={{
                    marginLeft:
                      tooltipPos.height <= 40 ? 0 : -(400 - tooltipPos.width),
                  }}
                >
                  {t(title, title)}
                </div>
              )}
            </>
          </div>
        </>
      );
      break;

    case "bottom":
    case "bottom-start":
    default:
      return (
        <>
          <div className="has-tooltip">
            <>
              {children}
              {title && (
                <div
                  className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow justify-start mt-1  tooltip text-fwhite max-w-[400px] text-xs transition-opacity"
                  ref={tooltipRef}
                >
                  {t(title, title)}
                </div>
              )}
            </>
          </div>
        </>
      );
      break;
  }
}

export default Tooltip;
