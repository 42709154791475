import React from "react";
import Option from "./parameters/Option";
import Textbox from "./parameters/Textbox";
import Selector from "../layout/Selector";
import PasswordBox from "./parameters/PasswordBox";
import FileUploader from "./parameters/FileUploader";
function Parameter({ definition, mode, profileId }) {
  if (definition) {
    if (definition.hidden) {
      return <></>;
    }
    switch (definition.type) {
      case "menu":
      case "solvedMenu":
      case "linkedSolvedMenu":
      case "queryMenu":
        return (
          <>
            <Option definition={definition} mode={mode} />
          </>
        );

      case "text":
        return (
          <Textbox definition={definition} mode={mode} profileId={profileId} />
        );
      case "queryText":
        return (
          <Textbox
            definition={definition}
            mode={mode}
            profileId={profileId}
            prefilled={definition.prefilled}
          />
        );
      case "password":
        return (
          <PasswordBox
            definition={definition}
            mode={mode}
            profileId={profileId}
          />
        );
      case "localAdmin":
        return (
          <Selector
            id={definition.id}
            defaultSelectedUserIds={[...definition.selected.users]}
            defaultSelectedGroupIds={[...definition.selected.groups]}
            disabled={!mode}
            profileId={profileId}
          />
        );
      case "file":
        return (
          <FileUploader
            id={definition.id}
            file_type={definition.file_type}
            file_name={definition.selected && definition.selected}
            mode={mode}
            profileId={profileId}
            extension={definition.extension}
            maxSize={definition.max_size}
          />
        );
      default:
        return <p></p>;
    }
  }
}

export default Parameter;
