import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "../components/layout/Sidebar";
import ObjectList from "../components/policy/ObjectList";
import { postApi, uploadFile, canceled, getApi } from "../tools/axiosInstances";
import PolicyMgmtOnboard from "../components/policy/PolicyMgmtOnboard";
import { ValidateRole, useCheckLicenseStatus } from "../tools/utilties";
import LoadingTable from "../components/layout/loading/LoadingTable";
import { PortalContext } from "../PortalContext";
import DeviceModeBar from "../components/layout/DeviceModeBar";
import IpadOnboarding from "../components/device/IpadOnboarding";
import ActionButton from "../components/layout/buttons/ActionButton";
import { ReactComponent as SaveIcon } from "../components/icons/save.svg";
import { ReactComponent as CancelIcon } from "../components/icons/cancelX.svg";
import { ReactComponent as EditIcon } from "../components/icons/edit.svg";
function Policy() {
  const { t } = useTranslation("global");
  const { deviceMode, windowsPOnboarded, ipadPOnboarded, ipadOnboarded } =
    useContext(PortalContext);
  const [reload, setReload] = useState(0);
  const [taskId, setTaskId] = useState(0);
  const [filter, setFilter] = useState("99999999-9999-9999-9999-999999999999");
  const [selected, setSelected] = useState([]);
  const globalId = "99999999-9999-9999-9999-999999999999";
  const [policies, setPolicies] = useState(false);
  const [editMode, setEditMode] = useState(false);
  useCheckLicenseStatus()
  useEffect(() => {
    let controller = new AbortController();
    getApi("/policy/", {}).then((list) => {
      setPolicies(list.data);
    });
    return () => {
      controller.abort();
    };
  }, []);
  useEffect(() => {
    let controller = new AbortController();
    getApi(`/policy/status/${filter}`, {}, controller.signal)
      .then((prof_status) => {
        for (const [profile, status] of Object.entries(prof_status.data)) {
          let elem = document.getElementById(`status_${profile}`);
          if (elem) {
            elem.value = JSON.stringify(status);
            elem.dispatchEvent(new Event("input", { bubbles: true }));
          }
        }
      })
      .catch((err) => {
        if (!canceled(err)) {
          console.error(err);
        }
      });
    return () => {
      controller.abort();
    };
  }, [filter, reload, deviceMode]);

  useEffect(() => {
    ValidateRole();
  }, []);

  const getLocalAdminValues = (elem) => {
    try {
      let selectedUsers = [];
      let selectedGroups = [];
      elem.selectedPeople.map((selected) => {
        if (selected.userPrincipalName) {
          selectedUsers.push(selected.id);
        } else if (selected.securityIdentifier) {
          selectedGroups.push(selected.id);
        }
      });
      return { users: selectedUsers, groups: selectedGroups };
    } catch (error) {
      console.log(error);
    }
  };

  async function uploadFileParameter(parameter) {
    const picker = document.getElementById(parameter.id);
    const formData = new FormData();
    let result = null;
    formData.append("file_data", picker.files[0]);
    formData.append("file_name", parameter.id);
    formData.append("file_type", parameter.file_type);

    const uploaded = await uploadFile("/library/upload/", formData);
    return uploaded.data;
  }

  function gatherLocalAdmins(parameter) {
    const elem = document.getElementById(parameter);
    return getLocalAdminValues(elem);
  }

  async function gatherParameters(parameterArray) {
    let parameterValues = [];
    if (parameterArray) {
      for (let i = 0; i < parameterArray.length; i++) {
        const parameter = parameterArray[i];
        if(parameter.hidden){continue}
        if (parameter.type == "query") {
          return [];
        }
        const elem = document.getElementById(parameter.id);
        switch (parameter.type) {
          case "text":
            if (elem.value.match(parameter.validator) == null) {
              console.error("Text not matching validator");
              throw "Empty not supported";
            }
            parameterValues.push({ id: parameter.id, value: elem.value });
            break;
          case "localAdmin":
            // console.log(selectedItems);
            parameterValues.push({
              id: parameter.id,
              value: getLocalAdminValues(elem),
            });
            break;
          case "file":
            const up = await uploadFileParameter(parameter);
            {
              parameterValues.push({
                id: parameter.id,
                value: up.id,
              });
            }

            break;
          default:
            parameterValues.push({ id: parameter.id, value: elem.value });
            break;
        }
      }
    }
    return parameterValues;
  }
  const parametersModified = (parameters) => {
    let modified = false;
    parameters.map((p) => {
      switch (p.type) {
        case "localAdmin":
          const initial = JSON.parse(sessionStorage.getItem(p.id));
          const actual = gatherLocalAdmins(p.id);
          modified =
            JSON.stringify(initial.groups.sort()) !=
              JSON.stringify(actual.groups.sort()) ||
            JSON.stringify(initial.users.sort()) !=
              JSON.stringify(actual.users.sort());
          modified && sessionStorage.setItem(p.id, JSON.stringify(actual)); // Saving the new values on SessionStorage
          break;
        case "file":
          if (p.file_value == "URL") {
            try {
              const newFile = document.getElementById(p.id).files[0].name;
              sessionStorage.setItem(p.id, newFile); // Saving the new values on SessionStorage
              modified = true;
            } catch {
              modified = false;
            }
          }
          break;
        case "query":
          break;
        default:
          try {
            if (
              sessionStorage.getItem(p.id) !=
              document.getElementById(p.id).value
            ) {
              modified = true;
              sessionStorage.setItem(p.id, document.getElementById(p.id).value); // Saving the new values on SessionStorage
            }
          } catch {
            modified = false;
          }
          break;
      }
    });
    return modified;
  };
  const savePolicies = async () => {
    setEditMode(!editMode);
    let profiles = [];
    for (
      let index = 0;
      index < Object.values(policies[deviceMode]).length;
      index++
    ) {
      const object = Object.values(policies[deviceMode])[index];
      for (let j = 0; j < object.length; j++) {
        const profile = object[j];
        const initial = sessionStorage.getItem(profile.id) === "true";
        let actual = false;
        try {
          actual = document.getElementById(profile.id).checked;
        } catch {
          continue; // If the switch is not there yet, ignore and continue with the rest of the policies
        }
        if (
          initial != actual ||
          (parametersModified(profile.parameters) && actual)
        ) {
          let params = [];
          if (actual) {
            params = await gatherParameters(profile.parameters);
          }
          try {
            profiles.push({
              profile: profile.id,
              enabled: actual,
              parameters: params,
            });
            sessionStorage.setItem(profile.id, actual); // Saving the new values on SessionStorage
          } catch (error) {
            console.log("Catch!" + error + profiles);
            continue;
          }
        }
        for (let h = 0; h < profile.childs.length; h++) {
          const child = profile.childs[h];
          const initial_child = sessionStorage.getItem(child.id) === "true";
          let actual_child;
          try {
            actual_child = document.getElementById(child.id).checked;
          } catch {
            continue; // If the switch is not there yet, ignore and continue with the rest of the policies
          }
          if (
            actual && //only applies if the parent is enabled
            (initial_child != actual_child ||
              (parametersModified(child.parameters) && actual_child))
          ) {
            let params = [];
            if (actual_child) {
              params = await gatherParameters(child.parameters);
            }
            try {
              profiles.push({
                profile: child.id,
                enabled: actual_child,
                parameters: params,
              });
              sessionStorage.setItem(child.id, actual_child); // Saving the new values on SessionStorage
            } catch {
              continue;
            }
          } else if (initial != actual && !actual) {
            // If the parent  changed and is now disabled, add all the children as disabled
            try {
              profiles.push({
                profile: child.id,
                enabled: false,
                parameters: gatherParameters(child.parameters),
              });
              sessionStorage.setItem(child.id, false); // Saving the new values on SessionStorage
            } catch {
              continue;
            }
          }
        }
      }
    }
    if (profiles.length > 0) {
      let req_body = {
        kind: "asg",
        target: "pol",
        detail: {
          scope: filter,
          profiles: profiles,
        },
      };
      // console.log(req_body)
      postApi("/tasks/", req_body).then((response) => {
        setTaskId(response.data.identifier);
      });
    }
    // setReload((p) => p + 1);
  };

  function toggleEdit() {
    setEditMode(!editMode);
  }

  onbeforeunload = (event) => {
    if (editMode) {
      event.preventDefault();
      event.returnValue = "Changes will be lost.";
    }
  };

  function profileSelected(id, status, parameters) {
    let tmpSelected = selected;
    if (status === "remove") {
      delete tmpSelected[id];
    } else {
      tmpSelected[id] = { status: status, parameters: parameters };
    }
    setSelected(tmpSelected);
  }

  function toggleCancel() {
    setEditMode(false);
    setSelected([]);
    setReload((p) => p + 1);
  }

  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between ">
          <div className="text-2xl font-medium">
            {t("pages.policy.title")}
            <br />
          </div>
          <div className="justify-end">
            {(deviceMode == "windows" && windowsPOnboarded) ||
            (deviceMode == "ipad" && ipadPOnboarded) ? (
              editMode ? (
                <span className="flex space-x-2">
                  <ActionButton Icon={CancelIcon} onClick={toggleCancel} subtle>
                    {t("words.cancel")}
                  </ActionButton>
                  <ActionButton Icon={SaveIcon} onClick={savePolicies} subtle>
                    {t("pages.policy.save")}
                  </ActionButton>
                </span>
              ) : (
                <ActionButton Icon={EditIcon} onClick={toggleEdit} subtle>
                  {t("pages.policy.edit")}
                </ActionButton>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Content */}
      {windowsPOnboarded === "pending" || ipadPOnboarded === "pending" ? (
        <>
          <div className="mx-auto max-w-6xl m-5 flex  ">
            <LoadingTable />
          </div>
        </>
      ) : deviceMode == "ipad" && ipadOnboarded !== true ? (
        <>
          <div className="mx-auto max-w-6xl m-5 px-4 z-20 sticky">
            <DeviceModeBar target="policy" />
            <IpadOnboarding target="policy" />
          </div>
        </>
      ) : (deviceMode == "windows" && windowsPOnboarded) ||
        (deviceMode == "ipad" && ipadPOnboarded == true) ? (
        <>
          <div className="mx-auto max-w-6xl m-5 flex  ">
            <div className="w-auto mr-5 mb-5 ">
              <Sidebar
                filter={filter}
                setFilter={setFilter}
                globalId={globalId}
              ></Sidebar>
            </div>
            <div className="w-full">
              <div className="w-full z-20 sticky">
                <DeviceModeBar target="policy" />
              </div>
              <div className="w-full overflow-y-scroll mb-12  relative z-0">
                {policies ? (
                  <ObjectList
                    policies={policies}
                    scope={filter}
                    // setSelected={profileSelected}
                    listSelected={selected}
                    mode={editMode}
                    reload={reload}
                    setReload={setReload}
                  />
                ) : (
                  <LoadingTable />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <PolicyMgmtOnboard />
      )}
    </>
  );
}

export default Policy;
